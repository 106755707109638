<template>
	<div>
		<div class="d-flex justify-space-between align-center mb-2 mt-6">
			<div class="input-wrap">
				<h5 class="mb-2">Recetas</h5>
			</div>
			<div class="action-btn-wrap"></div>
		</div>
		<v-row dense>
			<v-col cols="12" xl="3" lg="4" md="4" sm="12">
				<el-input
					placeholder="Buscar..."
					v-model="search.value"
					style="width: 100%;"
					prefix-icon="el-icon-search"
					@input="getRecords"
				>
				</el-input>
			</v-col>
		</v-row>
		<div class="shop-content-wrap text-center">
			<v-row dense>
				<v-col
					v-for="(row, index) in records"
					cols="12"
					sm="6"
					md="4"
					lg="3"
					class="d-flex align-items-stretch"
					:key="index"
					:index="customIndex(index)"
				>
					<v-card hover color="secondary">
						<v-img
							:src="row.image"
							class="align-center"
							gradient="to bottom, rgba(0,0,0,.7), rgba(0,0,0,.9)"
							height="200px"
						>
							<p class="text-center font-weight-medium mt-3 ma-1">
								{{ row.name }}
							</p>
						</v-img>

						<v-card-text class="text--primary">
							<template v-for="(ingredient, i) in row.ingredients">
								<div class="my-2 font-weight-medium" :key="i">
									{{ ingredient.ingredient.name }} - {{ ingredient.quantity }} -
									{{ ingredient.unit_type.description }}
								</div>
								<v-divider :key="ingredient.ingredient.name"></v-divider>
							</template>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
			<v-pagination
				@next="getRecords"
				@previous="getRecords"
				@input="getRecords"
				:length="pagination.last_page"
				:total-visible="7"
				v-model="pagination.current_page"
				circle
				prev-icon="mdi-chevron-left"
				next-icon="mdi-chevron-right"
			></v-pagination>
		</div>
	</div>
</template>

<script>
import queryString from 'query-string';

export default {
	data() {
		return {
			showDialog: false,
			resource: 'recipes',
			recordId: null,
			records: [],
			pagination: {},
			search: {
				value: null,
			},
			imageUrl: null,
		};
	},
	created() {
		this.$eventHub.$on('reloadData', (resource) => {
			if (this.resource == resource) {
				this.getRecords();
			}
		});
	},
	async mounted() {
		await this.getRecords();
	},
	methods: {
		customIndex(index) {
			return this.pagination.per_page * (this.pagination.current_page - 1) + index + 1;
		},
		getRecords() {
			return this.$http
				.get(`/${this.resource}/records?${this.getQueryParameters()}`)
				.then((response) => {
					this.records = response.data.data;
					this.pagination = response.data.meta;
					this.pagination.per_page = parseInt(response.data.meta.per_page);
				});
		},
		getQueryParameters() {
			return queryString.stringify({
				page: this.pagination.current_page,
				limit: this.limit,
				...this.search,
			});
		},
		clickCreate(recordId = null) {
			this.recordId = recordId;
			this.showDialog = true;
		},
		clickDelete(id) {
			this.destroy(`/${this.resource}/${id}`).then(() =>
				this.$eventHub.$emit('reloadData', this.resource)
			);
		},
	},
};
</script>
